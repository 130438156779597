import { Alert, Box, Divider, Typography } from "@mui/material";
import useCurrencyFormatter from "hooks/useCurrencyFormatter";
import React from "react";
import { useTranslation } from "react-i18next";

function RequestDetails({ orderData }) {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencyFormatter();

  const allStatus = {
    pending: t("orders.pending"),
    completed: t("orders.completed"),
    cancelled: t("orders.cancelled"),
    delivered: t("orders.delivered"),
    returned: t("orders.returned"),

    active: t("orders.active"),
    inactive: t("orders.inactive"),
    deleted: t("orders.deleted"),

    paid: t("orders.paid"),
    failed: t("orders.failed"),
    refunded: t("orders.refunded"),

    processing: t("orders.processing"),

    // Additional statuses
    declined: t("orders.declined"),
    approved: t("orders.approved"),
    reversed: t("orders.reversed"),
  };

  const transactionTypes = {
    Recharge: t("transactions.recharge"),
    Deduction: t("transactions.deduction"),
    Refund: t("transactions.refund"),
    registration_fees: t("transactions.registration_fees"),
    contract_fees: t("transactions.contract_fees"),
    Withdrawal: t("transactions.withdrawal"),
  };

  return (
    <Box
      sx={{
        p: 4,
        borderRadius: 2,
      }}
    >
      {/* Order ID */}
      <Box mb={2}>
        <Typography variant="body2" color="textSecondary">
          {t("report.order_id")}
        </Typography>
        <Typography variant="body1" fontWeight="500">
          {orderData.order_id}
        </Typography>
      </Box>

      <Divider />

      {/* Action By */}
      <Box my={2}>
        <Typography variant="body2" color="textSecondary">
          {t("report.action_by")}
        </Typography>
        <Typography variant="body1" fontWeight="500">
          {orderData.action_by.name}
        </Typography>
      </Box>

      <Divider />

      {/* Transaction Types */}
      <Box my={2}>
        <Typography variant="body2" color="textSecondary">
          {t("report.transaction_type")}
        </Typography>
        <Typography variant="body1" fontWeight="500">
          {transactionTypes[orderData.type] || orderData.type}
        </Typography>
      </Box>

      <Divider />

      {/* Completed Time */}
      <Box my={2}>
        <Typography variant="body2" color="textSecondary">
          {t("report.completed_time")}
        </Typography>
        <Typography variant="body1" fontWeight="500">
          {orderData.completedTime
            ? new Date(orderData.completedTime).toLocaleString()
            : t("report.not_completed_yet")}
        </Typography>
      </Box>

      <Divider />

      {/* Amount */}
      <Box my={2}>
        <Typography variant="body2" color="textSecondary">
          {t("report.amount")}
        </Typography>
        <Typography variant="body1" fontWeight="500">
          {formatCurrency(orderData.amount)}
        </Typography>
      </Box>

      <Divider />

      {/* Status */}
      <Box my={2}>
        <Typography variant="body2" color="textSecondary">
          {t("report.status")}
        </Typography>
        <Typography
          variant="body1"
          fontWeight="500"
          color={orderData.status === "approved" ? "success.main" : "error.main"}
        >
          {allStatus[orderData.status] || orderData.status}
        </Typography>
      </Box>

      <Divider />

      {/* Note */}
      <Box my={2} pb={2}>
        <Typography variant="body2" color="textSecondary">
          {t("report.note")}
        </Typography>
        <Typography variant="body1" fontWeight="500">
          {orderData.note}
        </Typography>
      </Box>

      {/* Rejection Reason - Show as alert if status is "rejected" */}
      {orderData.status === "rejected" && (
        <Alert severity="error" sx={{ mt: 2 }}>
          <strong>{t("report.rejected_reason")}:</strong> {orderData.note}
        </Alert>
      )}
    </Box>
  );
}

export default RequestDetails;
