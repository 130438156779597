import {
  Box,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Card, CardHeader, Container, Row, Table } from "reactstrap";
// core components
import { useQuery } from "@tanstack/react-query";
import BreadCrumbs from "components/BreadCrumbs";
import GlobalModal from "components/GlobalModal/GlobalModal";
import useThemeMode from "hooks/useThemeMode";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GetOrders } from "./api";
import useUserPermissions from "hooks/useUserPermissions";
import EmptyContentMessage from "components/EmptyContentMessage/EmptyContentMessage";

import VisibilityIcon from "@mui/icons-material/Visibility";
import useCurrencyFormatter from "hooks/useCurrencyFormatter";
import { useTheme } from "@emotion/react";

const thStyle = {
  textAlign: "center",
  padding: "20px ",
  fontSize: "12px",
};

function Orders() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [orderData, setOrderData] = useState({});

  const { mode } = useSelector((state) => state.theme);
  const themMode = useThemeMode(mode);
  const all_permissions = useUserPermissions();
  const { formatCurrency } = useCurrencyFormatter();

  // Get all products
  const { data: orders, isLoading } = useQuery({
    queryKey: ["orders", { page }],
    queryFn: () => GetOrders(page),
  });

  function openDetails() {
    setOpenFormModal(true);
  }

  return (
    <>
      <Box sx={{ minHeight: "calc( 100vh - 123px) " }}>
        {/* Page content */}
        <Container fluid>
          <BreadCrumbs pageName={t("orders.name")} />
          {/* Table */}
          <Row>
            <div className="col">
              <Card
                style={{
                  backgroundColor: themMode === "dark" ? "#1F2A37" : "",
                }}
              >
                <CardHeader className={`border-0 bg-${themMode !== "dark" || "transparent"}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <h3 className={`mb-0 text-${themMode === "light" ? "black" : "white"}`}>
                        {t("orders.name")}
                      </h3>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    ></Box>
                  </Box>
                </CardHeader>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : orders?.data?.length > 0 ? (
                  <Table className={`align-items-center  table-${mode} table-flush`} responsive>
                    <thead
                      style={{
                        backgroundColor: themMode === "dark" ? "#374151" : "#F9FAFB",
                        color: "#6B7280",
                      }}
                    >
                      <tr>
                        {/* Order number */}
                        <th style={thStyle} scope="col">
                          {t("orders.order_number")}
                        </th>
                        {/* User name */}
                        <th style={thStyle} scope="col">
                          {t("orders.user_name")}
                        </th>
                        {/* Total amount */}
                        <th style={thStyle} scope="col">
                          {t("orders.total_amount")}
                        </th>
                        {/* Payment status */}
                        <th style={thStyle} scope="col">
                          {t("orders.payment_status")}
                        </th>
                        {/* payment method */}
                        <th style={thStyle} scope="col">
                          {t("orders.payment_method")}
                        </th>
                        {/* payment reference */}
                        <th style={thStyle} scope="col">
                          {t("orders.payment_reference")}
                        </th>
                        {/* Status  */}
                        <th style={thStyle} scope="col">
                          {t("orders.status")}
                        </th>
                        {/* Show Details  */}
                        <th style={thStyle} scope="col">
                          {t("orders.details")}
                        </th>
                      </tr>
                    </thead>
                    {all_permissions?.includes("Fetch orders") && (
                      <tbody
                        style={{
                          backgroundColor: themMode === "dark" ? "#1F2A37" : "white",
                        }}
                      >
                        {orders?.data?.map((order, index) => (
                          <tr key={index}>
                            {/* Order number */}
                            <td className="text-center">{order?.order_number || "-"}</td>
                            {/* User name */}
                            <td className="text-center">{order?.sold_to?.name || "-"}</td>
                            {/* Total amount */}
                            <td className="text-center">
                              {order?.total_amount ? formatCurrency(order?.total_amount) : "-"}
                            </td>
                            {/* Payment status */}
                            <td className="text-center">{order?.payment_status}</td>
                            {/* payment method */}
                            <td className="text-center">{order?.payment_method}</td>
                            {/* payment reference */}
                            <td className="text-center">{order?.payment_reference?.order_id}</td>
                            {/* Status  */}
                            <td className="text-center">{order?.status}</td>
                            {/* Show Details  */}
                            <td className="text-center">
                              <Tooltip title="More details">
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setOrderData(order);
                                    openDetails(order);
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                ) : (
                  <EmptyContentMessage buttonText={false} />
                )}

                {/* Pagination */}
                <Stack
                  direction="row"
                  sx={{
                    margin: "10px 0",
                    justifyContent: "center",
                    padding: "10px",
                    display: orders?.data?.data?.length > 0 ? "flex" : "none",
                  }}
                  gap={2}
                  justifyContent={"center"}
                >
                  <Pagination
                    count={orders?.data?.last_page || 1}
                    variant="outlined"
                    shape="rounded"
                    value={page}
                    onChange={(e, value) => setPage(value)}
                  />
                </Stack>
              </Card>
            </div>
          </Row>
        </Container>
      </Box>

      {/* Create || Edit product modal */}
      <GlobalModal
        modalHeaderTypography={t("orders.details")}
        open={openFormModal}
        handleClose={() => {
          setOpenFormModal(false);
        }}
      >
        <ProductForm
          handleClose={() => setOpenFormModal(false)}
          order={orderData ? orderData : {}}
        />
      </GlobalModal>
    </>
  );
}

export default Orders;

function ProductForm({ order = {} }) {
  const theme = useTheme(); // Access the current theme
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencyFormatter();
  const isDarkMode = theme.palette.mode === "dark"; // Check if the current theme is dark mode

  const allStatus = {
    pending: t("orders.pending"),
    completed: t("orders.completed"),
    cancelled: t("orders.cancelled"),
    delivered: t("orders.delivered"),
    returned: t("orders.returned"),

    active: t("orders.active"),
    inactive: t("orders.inactive"),
    deleted: t("orders.deleted"),

    paid: t("orders.paid"),
    failed: t("orders.failed"),
    refunded: t("orders.refunded"),

    processing: t("orders.processing"),
  };

  const paymentMethods = {
    nfc: t("orders.nfc"),
    cash: t("orders.cash"),
    face: t("orders.face"),
  };

  return (
    <Card
      style={{
        backgroundColor: isDarkMode ? "#162846" : theme.palette.background.secondary,
        color: theme.palette.text.primary,
      }}
    >
      <CardContent>
        {/* Order Information */}
        <Typography variant="h5" color={theme.palette.text.primary} gutterBottom>
          {t("orders.order")}: {order?.order_number}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("orders.status")}: {allStatus[order?.status] || order?.status}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {t("orders.payment_status")}: {allStatus[order?.payment_status] || order?.payment_status}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {t("orders.total_amount")}: {formatCurrency(order?.total_amount)}
        </Typography>
        <Typography variant="body2">
          {t("orders.ordered_on")}: {new Date(order?.created_at).toLocaleDateString()}
        </Typography>

        <Divider style={{ margin: "20px 0", backgroundColor: theme.palette.divider }} />

        {/* Customer Information */}
        <Typography variant="h6" color={theme.palette.text.primary}>
          {t("orders.customer_Details")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>
              {t("global.name")}: {order?.sold_to?.name}
            </Typography>
            <Typography>
              {t("global.email")}: {order?.sold_to?.email}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {t("global.phone")}: {order?.sold_to?.user_phone}
            </Typography>
            <Typography>
              {t("global.balance")}: {formatCurrency(order?.sold_to?.balance)}
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ margin: "20px 0", backgroundColor: theme.palette.divider }} />

        {/* Product Information */}
        <Typography variant="h6" color={theme.palette.text.primary}>
          {t("products.name")}
        </Typography>
        {order?.products?.map((product, index) => (
          <Box
            key={index}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: "8px",
              padding: "16px",
              marginBottom: "16px",
              backgroundColor: isDarkMode
                ? theme.palette.background.default
                : theme.palette.background.paper,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("orders.product")}: {product?.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t("orders.SKU")}: {product?.sku}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product?.description}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  {t("products.quantity")}: {product?.pivot?.quantity}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  {t("orders.product_price")}: {formatCurrency(product?.pivot?.price)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}

        <Divider style={{ margin: "20px 0", backgroundColor: theme.palette.divider }} />

        {/* Payment Information */}
        <Typography variant="h6" color={theme.palette.text.primary}>
          {t("orders.payment_details")}
        </Typography>
        <Typography>
          {t("orders.payment_method")}:{" "}
          {paymentMethods[order?.payment_method] || order?.payment_method}
        </Typography>
        <Typography>
          {t("orders.total_amount")}: {formatCurrency(order?.payment_reference?.amount)}
        </Typography>

        <Divider style={{ margin: "20px 0", backgroundColor: theme.palette.divider }} />

        {/* Machine Information */}
        <Typography variant="h6" color={theme.palette.text.primary}>
          {t("orders.device_info")}
        </Typography>
        <Typography>
          {t("orders.device")}: {order?.machine?.name}
        </Typography>
        <Typography>
          {t("orders.machine_code")}: {order?.machine?.code}
        </Typography>

        <Divider style={{ margin: "20px 0", backgroundColor: theme.palette.divider }} />

        {/* Note */}
        <Typography variant="h6" color={theme.palette.text.primary}>
          {t("orders.note")}
        </Typography>
        <Typography>
          {t("orders.note")}: {order?.note}
        </Typography>
      </CardContent>
    </Card>
  );
}
