import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import Api from "../../config/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { notifySuccess, notifyError } from "utilites/toastify";
import BreadCrumbs from "components/BreadCrumbs";
import "../../css/index.css";
import useUserPermissions from "hooks/useUserPermissions";

const fetchRegistrationFees = async () => {
  const res = await Api.get("api/settings/registration-fees");

  return res.data.registration_fees; // Return the actual fees value
};

const fetchContractFees = async () => {
  const res = await Api.get("api/finance/contract-fees");

  return res.data.contract_fees; // Return the contract fees value
};

const updateFees = async (endpoint, data) => {
  const res = await Api.post(endpoint, data);
  return res.data;
};

export default function Settings() {
  const { t, i18n } = useTranslation();
  const { mode } = useSelector((state) => state.theme);
  const [registrationFees, setRegistrationFees] = useState("");
  const [contactFees, setContractFees] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const all_permissions = useUserPermissions();

  useEffect(() => {
    const fetchFees = async () => {
      try {
        setIsLoading(true);

        const promises = [];
        if (all_permissions.includes("Get registration fees")) {
          promises.push(fetchRegistrationFees().then(setRegistrationFees));
        }
        if (all_permissions.includes("Get contract fees")) {
          promises.push(fetchContractFees().then(setContractFees));
        }

        await Promise.all(promises);
      } catch (error) {
        console.error("Failed to fetch fees", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFees();
  }, []);

  const mutation = useMutation({
    mutationFn: async ({ endpoint, newFees }) => updateFees(endpoint, newFees),
    onSuccess: () => {
      notifySuccess("Fees updated successfully.");
    },
    onError: () => {
      notifyError("Failed to update fees.");
    },
  });

  const handleSubmit = (e, endpoint, fees) => {
    e.preventDefault();
    mutation.mutate({ endpoint, newFees: fees });
  };

  return (
    <Box minHeight="89.5vh" sx={{ padding: "36px" }}>
      {/* BreadCrumbs Navigation */}
      <BreadCrumbs pageName={t("settings.title")} />

      {/* Settings Form */}
      <Box
        sx={{
          borderRadius: "16px",
          width: "100%",
          typography: "body1",
          backgroundColor: mode == "dark" ? "rgb(31, 42, 55)" : "white",
          padding: "24px",
        }}
      >
        <Typography
          fontWeight="bold"
          variant="h6"
          sx={{ textAlign: "start", color: mode == "dark" ? "#fff" : "#000" }}
        >
          {i18n.language == "en" ? "Settings" : "الاعدادات"}
        </Typography>
        <Typography
          sx={{ color: "gray", marginBottom: "16px", marginTop: "8px", textAlign: "start" }}
          variant="subtitle2"
        >
          {i18n.language == "en" ? "Student Registration Fees" : "رسوم تسجيل الطلاب"}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Stack gap={3}>
            {/* Registration fees */}
            <form
              onSubmit={(e) =>
                handleSubmit(e, "api/settings/registration-fees", {
                  registration_fees: registrationFees,
                })
              }
            >
              <Stack direction={"row"} gap={3}>
                <TextField
                  label={t("settings.registration_fees.label")}
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  value={registrationFees} // Bind to state
                  onChange={(e) => setRegistrationFees(e.target.value)} // Allow user input
                  disabled={isLoading || !all_permissions.includes("Update registration fees")}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "10px",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>{i18n.language === "en" ? "SAR" : "ريال"}</InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    flexShrink: "0",
                    width: "160px",
                    color: "#fff",
                    backgroundColor: "#255F6D",
                    fontSize: "15px",
                    transition: ".4s",
                    padding: "5px 20px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#255F6D",
                      opacity: ".8",
                    },
                  }}
                  disabled={isLoading || !all_permissions.includes("Update registration fees")}
                >
                  {i18n.language == "en" ? "Save" : "حفظ"}
                </Button>
              </Stack>
            </form>

            {/* Contract fees */}
            <form
              onSubmit={(e) =>
                handleSubmit(e, "api/finance/contract-fees", { contract_fees: contactFees })
              }
            >
              <Stack direction={"row"} gap={3}>
                <TextField
                  label={t("settings.contract_fees")}
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                  value={contactFees} // Bind to state
                  onChange={(e) => setContractFees(e.target.value)} // Allow user input
                  disabled={isLoading || !all_permissions.includes("Update contract fees")}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "10px",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment>%</InputAdornment>,
                  }}
                />
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    flexShrink: "0",
                    width: "160px",
                    color: "#fff",
                    backgroundColor: "#255F6D",
                    fontSize: "15px",
                    transition: ".4s",
                    padding: "5px 20px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#255F6D",
                      opacity: ".8",
                    },
                  }}
                  disabled={isLoading || !all_permissions.includes("Update contract fees")}
                >
                  {i18n.language == "en" ? "Save" : "حفظ"}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
