import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { Card, CardHeader, Container, Row, Table } from "reactstrap";
// core components
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import BreadCrumbs from "components/BreadCrumbs";
import GlobalModal from "components/GlobalModal/GlobalModal";
import CloseModel from "components/Modal/DeleteDialouge";
import useThemeMode from "hooks/useThemeMode";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "utilites/toastify";
import { AddCategory, DeleteCategory, GetCategories, UpdateCategory } from "./api";
import useUserPermissions from "hooks/useUserPermissions";
import EmptyContentMessage from "components/EmptyContentMessage/EmptyContentMessage";

import imagePlaceholder from "../../assets/img/global/imagePlaceholder.svg";
import { baseURL } from "config/api";
import { InfoOutlined } from "@mui/icons-material";

function Categories() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [confirm, setConfirm] = useState({ open: false, id: "" });
  const [categoryData, setCategoryData] = useState({});

  const { mode } = useSelector((state) => state.theme);
  const themMode = useThemeMode(mode);
  const all_permissions = useUserPermissions();

  // Get all canteens
  const { data: categories, isLoading } = useQuery({
    queryKey: ["categories", { page }],
    queryFn: () => GetCategories(page),
  });

  function handleClose() {
    setConfirm({ open: false, id: "" });
  }

  function handleCheck(category) {
    if (categoryData.id === category.id) {
      setCategoryData({});
    } else {
      setCategoryData(category);
    }
  }

  function openEdit() {
    if (!categoryData.id) return notifyError(t("categories.select_canteen_warning_1"));
    setOpenFormModal(true);
  }

  function openDelete() {
    if (!categoryData.id) return notifyError(t("categories.select_canteen_warning_1"));
    setConfirm({ open: true });
  }

  return (
    <>
      <Box sx={{ minHeight: "calc( 100vh - 123px) " }}>
        {/* Page content */}
        <Container fluid>
          <BreadCrumbs pageName={t("categories.name")} />
          {/* Table */}
          <Row>
            <div className="col">
              <Card
                style={{
                  backgroundColor: themMode === "dark" ? "#1F2A37" : "",
                }}
              >
                <CardHeader className={`border-0 bg-${themMode !== "dark" || "transparent"}`}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <h3 className={`mb-0 text-${themMode === "light" ? "black" : "white"}`}>
                        {t("categories.name")}
                      </h3>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      {all_permissions?.includes("Create categories") && (
                        <Button
                          onClick={() => setOpenFormModal(true)}
                          className={`${themMode === "dark" && "dark-button-man"}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            display: "flex",
                            gap: "5px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",

                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          {t("canteen.add")}
                          <AddCircleIcon sx={{ color: "#71DCD4" }} />
                        </Button>
                      )}
                      {all_permissions?.includes("Update categories") && (
                        <Button
                          onClick={openEdit}
                          className={`${themMode === "dark" && "dark-button-man"}`}
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            display: "flex",
                            gap: "5px",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                        >
                          {t("canteen.edit")}
                          <EditIcon />
                        </Button>
                      )}
                      {all_permissions?.includes("Delete categories") && (
                        <Button
                          sx={{
                            color: "black",
                            backgroundColor: "#fff",
                            fontSize: "15px",
                            transition: ".4s",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            padding: "8px 10px",
                            borderRadius: "10px",
                            display: "flex",
                            gap: "5px",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#fff",
                              opacity: ".8",
                            },
                          }}
                          className={`${themMode === "dark" ? "dark-button-man" : ""}`}
                          onClick={openDelete}
                        >
                          {t("canteen.delete")}
                          <DeleteIcon sx={{ color: "#C81E1E" }} />
                        </Button>
                      )}
                    </Box>
                  </Box>
                </CardHeader>
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : categories?.data?.data?.length > 0 ? (
                  <Table className={`align-items-center  table-${mode} table-flush`} responsive>
                    <thead
                      style={{
                        backgroundColor: themMode === "dark" ? "#374151" : "#F9FAFB",
                        color: "#6B7280",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px 0px",
                            fontSize: "12px",
                            width: "8px",
                          }}
                        ></th>
                        {/* Category Image */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("categories.category_image")}
                        </th>
                        {/* Category name */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("categories.category_name")}
                        </th>
                        {/* Description */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("categories.description")}
                        </th>
                        {/* Category parent */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("categories.category_parent")}
                        </th>
                        {/* Products count */}
                        <th
                          style={{
                            textAlign: "center",
                            padding: "20px ",
                            fontSize: "12px",
                          }}
                          scope="col"
                        >
                          {t("categories.products_count")}
                        </th>
                      </tr>
                    </thead>
                    {all_permissions?.includes("Fetch categories") && (
                      <tbody
                        style={{
                          backgroundColor: themMode === "dark" ? "#1F2A37" : "white",
                        }}
                      >
                        {categories?.data?.data?.map((category, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>
                              <Checkbox
                                onClick={() => handleCheck(category)}
                                checked={categoryData?.id === category.id}
                              />
                            </td>
                            {/* Category image */}
                            <th className="d-flex justify-content-center" scope="row">
                              <div
                                style={{ width: "60px", height: "60px" }}
                                className="overflow-hidden"
                              >
                                <img
                                  className="w-100"
                                  src={
                                    category?.image
                                      ? `${baseURL}/${category.image}`
                                      : imagePlaceholder
                                  }
                                  alt="Default category images"
                                  title="Default category images"
                                />
                              </div>
                            </th>
                            {/* Category name */}
                            <td style={{ textAlign: "center" }} scope="row">
                              {category?.name || "-"}
                            </td>
                            {/* Category description */}
                            <td
                              style={{ textAlign: "center", maxWidth: "400px", textWrap: "wrap" }}
                            >
                              {category?.description || "-"}
                            </td>
                            {/* Category parent */}
                            <td style={{ textAlign: "center" }}>
                              {category?.parent ? category.parent.name : "-"}
                            </td>
                            {/* Products count */}
                            <td style={{ textAlign: "center" }}>
                              {category?.products_count || "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                ) : (
                  <EmptyContentMessage
                    onClick={() => {
                      if (all_permissions?.includes("Create categories")) {
                        setOpenFormModal(true);
                      } else {
                        notifyError("You don't have permission to add.");
                      }
                    }}
                    buttonText={t("categories.add")}
                  />
                )}

                {/* Pagination */}
                <Stack
                  direction="row"
                  sx={{
                    margin: "10px 0",
                    justifyContent: "center",
                    padding: "10px",
                    display: categories?.data?.data?.length > 0 ? "flex" : "none",
                  }}
                  gap={2}
                  justifyContent={"center"}
                >
                  <Pagination
                    count={categories?.data?.last_page || 1}
                    variant="outlined"
                    shape="rounded"
                    value={page}
                    onChange={(e, value) => setPage(value)}
                  />
                </Stack>
              </Card>
            </div>
          </Row>
        </Container>
      </Box>

      {/* Confirmation before delete */}
      <CloseModel
        open={confirm.open}
        handleClose={handleClose}
        fun={DeleteCategory}
        id={categoryData.id}
        page={page}
        mutateFun={() => {
          queryClient.invalidateQueries({
            queryKey: ["categories"],
          });
        }}
        msg={t("categories.deleted_category")}
        onSuccessFunction={() => {
          setCategoryData({});
        }}
      />

      {/* Create || Edit category modal */}
      <GlobalModal
        modalHeaderTypography={
          categoryData?.id ? t("categories.update_category") : t("categories.create_category")
        }
        open={openFormModal}
        handleClose={() => {
          setOpenFormModal(false);
        }}
      >
        <CategoryForm
          handleClose={() => {
            setOpenFormModal(false);
          }}
          canteenId={categoryData?.id || null}
          branchId={categoryData?.branch_id || null}
          initialManagerId={categoryData?.manager_id || ""}
          canteenName={categoryData?.name || ""}
          setCategoryData={setCategoryData}
          categories={categories}
          category_data={categoryData}
          category_id={categoryData?.id}
        />
      </GlobalModal>
    </>
  );
}

export default Categories;

function formReducer(state, action) {
  const { type } = action;

  switch (type) {
    case "UPDATE_FIELD":
      return {
        ...state,
        [action.field]: { ...state[action.field], value: action.payload, error: "" },
      };
    case "SET_ERROR":
      if (action.field === "image") {
        return {
          ...state,
          [action.field]: { ...state[action.field], value: null, error: action.payload },
        };
      }
      return {
        ...state,
        [action.field]: { ...state[action.field], value: "", error: action.payload },
      };
    default:
      return state;
  }
}

const initialArg = {
  image: { value: null, error: "" },
  name: { value: "", error: "" },
  description: { value: "", error: "" },
  parent_id: { value: "", error: "" },
};

function CategoryForm({
  handleClose,
  category_id = "",
  setCategoryData,
  category_data = null,
  categories = [],
}) {
  const [img, setImg] = useState();

  const [state, dispatch] = useReducer(formReducer, initialArg);

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  // Fields to validate
  const requiredFields = [{ field: "name", message: t("products.name_is_required") }];

  const { mutate, isPending } = useMutation({
    mutationFn: category_id ? UpdateCategory : AddCategory,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["categories"],
      });
      notifySuccess(category_id ? t("categories.updated_success") : t("categories.added_success"));
      setCategoryData({});
      handleClose();
    },
    onError: (err) => {
      if (err.response) {
        const status = err.response.status;
        if (status === 422) {
          const errorMessages = [];
          // Loop through each key in the errors object and format the messages dynamically
          Object.entries(err?.response?.data?.errors).forEach(([field, messages]) => {
            // Format the field name and join the error messages
            errorMessages.push(`${capitalizeFirstLetter(field)}: ${messages.join(", ")}`);
          });

          // Notify each error message
          errorMessages.forEach(notifyError);
        } else {
          notifyError(err.message);
          err?.response?.data?.message && notifyError(err?.response?.data?.message);
        }
      }
    },
  });

  const handleChange = (e) => {
    const fieldError = requiredFields.find((item) => item.field === e.target.name);

    if (e.target.value === "" && fieldError) {
      // Find the corresponding error message for the current field
      if (fieldError) {
        dispatch({ type: "SET_ERROR", field: e.target.name, payload: fieldError.message });
      }
    } else {
      dispatch({ type: "UPDATE_FIELD", field: e.target.name, payload: e.target.value });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/webp", "image/jpeg", "image/jpg", "image/png", "image/avif"];

    if (file && allowedTypes.includes(file.type)) {
      dispatch({ type: "UPDATE_FIELD", field: "image", payload: file });
      const imageUrl = URL.createObjectURL(file);
      setImg(imageUrl);
    } else {
      dispatch({
        type: "SET_ERROR",
        field: "image",
        payload: "Invalid file type. Only WEBP, JPG, AVIF and PNG are allowed.",
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let thereIsError = false;

    // Loop over the fields and check if they are empty
    requiredFields.forEach(({ field, message }) => {
      const value = state[field].value;

      // General validation for other fields
      if (!value) {
        dispatch({ type: "SET_ERROR", field, payload: message });
        thereIsError = true;
      }
    });

    if (!thereIsError) {
      const formData = new FormData(e.target);

      // Process form data here (send to server, etc.)
      if (category_id) {
        mutate({ formData, id: category_id });
      } else {
        mutate(formData);
      }
    }
  };

  useEffect(() => {
    if (category_id) {
      for (const key in category_data) {
        if (Object.hasOwn(initialArg, key)) {
          dispatch({ type: "UPDATE_FIELD", field: key, payload: category_data[key] });
        }
      }
      if (category_data?.image) setImg(`${baseURL}/${category_data?.image}`);
    }
  }, [category_id, category_data]);

  return (
    <form onSubmit={onSubmit} className="py-4">
      {/* Category image */}
      <Box
        mb={3}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <input
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          name="image"
          onChange={handleImageUpload}
        />
        <label htmlFor="raised-button-file" className="d-block" style={{ width: "fit-content" }}>
          {t("categories.category_image")}
        </label>
        <label htmlFor="raised-button-file">
          <Button
            variant="raised"
            component="span"
            sx={{ backgroundColor: "#ffffff21", padding: "8px" }}
          >
            <Box
              component="img"
              sx={{
                height: "100px",
                width: "100px",
                objectFit: "cover",
              }}
              alt="Category Preview"
              src={img || imagePlaceholder}
            />
          </Button>
        </label>
        <small>
          Selected file: {state.image.value ? state.image.value.name : "No file selected"}
        </small>
        {state.image.error && (
          <FormHelperText error sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
            <InfoOutlined />
            <span style={{ fontSize: "14px" }}>{state.image.error}</span>
          </FormHelperText>
        )}
      </Box>

      {/* Category name */}
      <div className="mb-4">
        <TextField
          required
          fullWidth
          type="text"
          className="mb-1"
          variant="outlined"
          name="name"
          onChange={handleChange}
          value={state.name.value}
          label={t("categories.category_name")}
          error={state.name.error ? true : false}
        />
        {state.name.error && (
          <FormHelperText error sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
            <span style={{ fontSize: "14px" }}>{state.name.error}</span>
          </FormHelperText>
        )}
      </div>

      {/* Category description */}
      <div className="mb-4">
        <TextField
          label={t("categories.description")}
          variant="outlined"
          fullWidth
          className="mb-1"
          name="description"
          type="text"
          error={state.description.error ? true : false}
          value={state.description.value}
          onChange={handleChange}
        />
        {state.description.error && (
          <FormHelperText error sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
            <span style={{ fontSize: "14px" }}>{state.description.error}</span>
          </FormHelperText>
        )}
      </div>

      {/* Category parent */}
      <FormControl fullWidth className="mb-4" error={state.parent_id.error ? true : false}>
        <InputLabel id="CategoryParent">{t("categories.category_parent")}</InputLabel>
        <Select
          labelId="CategoryParent"
          id="selectCategoryParent"
          name="parent_id"
          label="Category parent"
          value={state.parent_id.value}
          onChange={handleChange}
        >
          <MenuItem value={"null"}>none</MenuItem>
          {categories?.data?.data?.map((category) => (
            <MenuItem value={category.id} key={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
        {state.parent_id.error && (
          <FormHelperText error sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
            <span style={{ fontSize: "14px" }}>{state.parent_id.error}</span>
          </FormHelperText>
        )}
      </FormControl>

      <Button type="submit" variant="contained" color="primary" disabled={isPending} fullWidth>
        {category_id ? t("canteen.update") : t("canteen.create")}
      </Button>
    </form>
  );
}

// Utility function to capitalize the first letter of each field (optional)
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).replace("_", " ");
}
