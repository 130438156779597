import Api from "config/api";

export const GetOrders = async (page) => {
  try {
    const res = await Api.get("/api/ecommerce/orders", {
      params: {
        per_page: 10,
        page: page,
      },
    });
    return res.data;
  } catch (error) {
    // console.error("Error fetching ser data:", error);
    return error;
  }
};
