import { Button as MuiButton, CardContent, Stack, Typography } from "@mui/material";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import GlobalModal from "components/GlobalModal/GlobalModal";
import Api from "config/api";
import useCurrencyFormatter from "hooks/useCurrencyFormatter";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Card } from "reactstrap";
import { notifyError } from "utilites/toastify";
import { notifySuccess } from "utilites/toastify";

const GetEarnings = async () => {
  const res = await Api.get(`/api/ecommerce/earnings`);
  return res.data;
};

const makeWithdrawal = async () => {
  const res = await Api.post("api/balances/withdrawal");

  return res.data.registration_fees; // Return the actual fees value
};

function EarningsDetails() {
  const { mode } = useSelector((state) => state.theme);
  const [confirm, setConfirm] = useState(false);

  const { t } = useTranslation();
  const { formatCurrency } = useCurrencyFormatter();
  const queryClient = useQueryClient();

  const {
    isLoading,
    error,
    data: earnings,
  } = useQuery({
    queryKey: ["earnings"],
    queryFn: () => GetEarnings(),
    onError: (err) => {
      const errors = err.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          notifyError(errors[key]);
        }
      }
    },
  });

  const mutation = useMutation({
    mutationFn: async () => makeWithdrawal(),
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries({
        queryKey: ["withdrawal_requests"],
      });
      notifySuccess("Request created successfully.");
      setConfirm(false);
    },
    onError: (err) => {
      if (err.response.data) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Failed Request to create request");
      }
    },
  });

  const handleClose = () => {
    setConfirm(false);
  };

  const handleConfirm = () => {
    mutation.mutate();
  };

  // console.log(earnings);

  return (
    <Card
      style={{
        backgroundColor: mode === "dark" ? "rgb(47 57 71)" : "#e9f3ff",
        maxWidth: 400,
        borderRadius: "10px",
      }}
      sx={{
        p: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h6" color="text.secondary">
            {t("report.earnings")}
          </Typography>
          <Typography variant="h4" color="primary" fontWeight="bold">
            {formatCurrency(earnings?.earnings || 0)}
          </Typography>

          <Typography variant="h6" color="text.secondary">
            {t("report.net_earnings")}
          </Typography>
          <Typography variant="h4" color="primary" fontWeight="bold">
            {formatCurrency(earnings?.net_earnings || 0)}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setConfirm(true)}
            sx={{
              mt: 2,
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
              backgroundColor: "#255F6D",
              "&:hover": {
                backgroundColor: "#1E4B56",
              },
            }}
          >
            {t("report.Withdrawal_request_btn")}
          </Button>
        </Stack>
      </CardContent>
      {/* Withdrawal request modal */}
      <GlobalModal
        modalHeaderTypography={t("settlementRequest.confirm_modal")}
        open={confirm}
        handleClose={handleClose}
      >
        <p style={{ fontSize: "24px" }} className="text-center my-4">
          {t("settlementRequest.confirm_modal_description")}
        </p>

        <Stack flexDirection={"row"} gap={3} justifyContent={"center"} alignItems={"center"}>
          <MuiButton
            variant="contained"
            sx={{ fontWeight: "500", fontSize: "1.1rem" }}
            color="primary"
            onClick={handleConfirm}
            disabled={mutation.isLoading}
          >
            {t("report.Withdrawal_request_btn")}
          </MuiButton>
          <MuiButton
            variant="contained"
            sx={{ fontWeight: "500", fontSize: "1.1rem" }}
            onClick={handleClose}
            color="error"
            disabled={mutation.isLoading}
          >
            {t("deleteModel.close")}
          </MuiButton>
        </Stack>
      </GlobalModal>
    </Card>
  );
}

export default EarningsDetails;
