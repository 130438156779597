import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "../../css/index.css";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "components/BreadCrumbs";
import { useSelector } from "react-redux";
import useThemeMode from "hooks/useThemeMode";
import useUserPermissions from "hooks/useUserPermissions";
import useFormatDate from "hooks/useFormatDate";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { notifyError } from "utilites/toastify";
import { ApproveWithdrawal, GetReportWithdrawals, RejectWithdrawal } from "./api";
import dayjs from "dayjs";
import { Card, CardHeader, Table, Row } from "reactstrap";
import { DatePicker } from "@mui/x-date-pickers";
import GlobalModal from "components/GlobalModal/GlobalModal";
import EmptyContentMessage from "../../components/EmptyContentMessage/EmptyContentMessage";
import RequestDetails from "components/Modules/WithdrawalRequest/RequestDetails";
import ActionDropdown from "components/Modules/WithdrawalRequests/ActionDropdown";
import { notifySuccess } from "utilites/toastify";

function SettlementRequests() {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [rejectNote, setRejectNote] = useState("");
  const [query, setQuery] = useState(`per_page=10`);
  const [openModal, setOpenModal] = useState(false);
  const [reportDetails, setReportDetails] = useState({});
  const [openRejectModal, setOpenRejectModal] = useState(false);

  const formatDate = useFormatDate();
  const { t, i18n } = useTranslation();
  const { mode } = useSelector((state) => state.theme);
  const themeMode = useThemeMode(mode);
  const all_permissions = useUserPermissions();
  const queryClient = useQueryClient();

  const statuses = [
    { value: "", title: t("settlementRequest.all") },
    { value: "pending", title: t("settlementRequest.pending") },
    { value: "declined", title: t("settlementRequest.declined") },
    { value: "cancelled", title: t("settlementRequest.cancelled") },
    { value: "approved", title: t("settlementRequest.approved") },
    { value: "reversed", title: t("settlementRequest.reversed") },
    { value: "failed", title: t("settlementRequest.failed") },
    { value: "paid", title: t("settlementRequest.paid") },
  ];

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["withdrawals", { query, page }],
    queryFn: () => GetReportWithdrawals(query, page),
    onError: (err) => {
      const errors = err.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          notifyError(errors[key]);
        }
      }
    },
  });

  const approveMutation = useMutation({
    mutationFn: (id) => ApproveWithdrawal(id),
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries({
        queryKey: ["withdrawals"],
      });
      notifySuccess("Approved successfully.");
    },
    onError: (err) => {
      if (err.response.data) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Failed to approve.");
      }
    },
  });

  const rejectMutation = useMutation({
    mutationFn: ({ id, note }) => RejectWithdrawal(id, { note: note }),
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries({
        queryKey: ["withdrawals"],
      });
      notifySuccess("Rejected successfully.");
      setRejectNote("");
      setOpenRejectModal(false);
    },
    onError: (err) => {
      if (err.response.data) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Failed to reject.");
      }
    },
  });

  const handleShowDetails = (data) => {
    setReportDetails(data);
    setOpenModal(true);
  };

  const handleAcceptRequest = (data) => {
    approveMutation.mutate(data.id);
  };

  const handleRejectRequest = () => {
    rejectMutation.mutate({ id: reportDetails.id, note: rejectNote });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const isClearButton = e.nativeEvent.submitter.getAttribute("data-clear-filters") === "true";

    // Extract and sanitize form values
    let selectedStatus = status;
    let to =
      formData
        .get("to")
        ?.replace(/[\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "")
        .trim() || "";
    let from =
      formData
        .get("from")
        ?.replace(/[\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "")
        .trim() || "";

    // Validate date range
    if (new Date(from) >= new Date(to)) {
      notifyError("The from date must be earlier than the to date.");
      return;
    }

    if (isClearButton) {
      setStatus("");
      setFromDate(null);
      setToDate(null);
      selectedStatus = "";
      to = "";
      from = "";
    }

    // Build query parameters
    const queryParams = new URLSearchParams();
    if (from) queryParams.append("from_date", `${dayjs(from).format("YYYY-MM-DD")} 00:00:00`);
    if (to) queryParams.append("to_date", `${dayjs(to).format("YYYY-MM-DD")} 00:00:00`);
    if (selectedStatus) queryParams.append("status", selectedStatus);
    queryParams.append("per_page", "10");

    // Set the query
    setQuery(queryParams.toString());
  };

  const statuses_colors = {
    pending: "warning",
    paid: "success",
    failed: "danger",
    refunded: "info",
    reversed: "info",
    cancelled: "danger",
    approved: "success",
    declined: "danger",
  };

  return (
    <Box minHeight="89.5vh" sx={{ padding: "36px" }}>
      <BreadCrumbs pageName={t("settlementRequest.name")} />
      <Box
        sx={{
          borderRadius: "16px",
          width: "100%",
          typography: "body1",
          backgroundColor: themeMode === "dark" ? "rgb(31, 42, 55)" : "white",
          padding: "24px",
        }}
      >
        <Typography
          fontWeight="bold"
          variant="h6"
          sx={{
            textAlign: "start",
            color: themeMode === "dark" ? "#fff" : "#000",
          }}
        >
          {t("settlementRequest.name")}
        </Typography>
        <Typography
          sx={{
            color: "gray",
            marginBottom: "16px",
            marginTop: "8px",
            textAlign: "start",
          }}
          variant="subtitle2"
        >
          {t("settlementRequest.description")}
        </Typography>

        <Box sx={{ minHeight: "calc( 100vh - 123px)" }}>
          {/* Page content */}

          <div className="p-0">
            <Row>
              <div className="col">
                <Card
                  style={{
                    backgroundColor: mode === "dark" ? "rgb(31, 42, 55)" : "white",
                  }}
                >
                  <CardHeader className={`border-0 bg-${mode !== "dark" || "transparent"}`}>
                    <form onSubmit={handleSubmit}>
                      <Stack gap={1} direction="row" justifyContent="center">
                        {/* Filter by date from */}
                        <Box sx={{ width: "100%", display: "grid" }}>
                          <label>{t("report.from")}</label>
                          <DatePicker
                            sx={{ width: "100%" }}
                            name="from"
                            slotProps={{
                              textField: {
                                required: false,
                              },
                            }}
                            value={fromDate}
                            onChange={(newValue) => setFromDate(newValue)}
                          />
                        </Box>

                        {/* Filter by date To */}
                        <Box sx={{ width: "100%", display: "grid" }}>
                          <label>{t("report.to")}</label>
                          <DatePicker
                            sx={{ width: "100%" }}
                            name="to"
                            slotProps={{
                              textField: {
                                required: false,
                              },
                            }}
                            value={toDate}
                            onChange={(newValue) => setToDate(newValue)}
                          />
                        </Box>
                      </Stack>

                      <Stack gap={1} direction="row" justifyContent="center" mt={2}>
                        {/* Select Status */}
                        <Box sx={{ width: "100%", display: "grid" }}>
                          <FormControl>
                            <InputLabel id="chose_status">{t("report.status")}</InputLabel>
                            <Select
                              labelId="chose_status"
                              id="select_status"
                              value={status}
                              label="Status"
                              onChange={(e) => setStatus(e.target.value)}
                              disabled={isLoading}
                            >
                              {statuses.map((status) => (
                                <MenuItem value={status.value} key={status.title}>
                                  {t(status.title)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Stack>

                      <Stack
                        gap={1}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"end"}
                        mt={2}
                      >
                        {/* Clear all filters */}
                        <Button
                          variant="outlined"
                          type="submit"
                          sx={{
                            padding: "8px 25px",
                            textTransform: "capitalize",
                            alignSelf: "flex-end",
                          }}
                          data-clear-filters="true"
                        >
                          {t("report.clear_filters")}
                        </Button>

                        {/* Apply filters */}
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            padding: "8px 25px",
                            textTransform: "capitalize",
                            alignSelf: "flex-end",
                          }}
                        >
                          {t("report.apply_filters")}
                        </Button>
                      </Stack>
                    </form>
                  </CardHeader>

                  {isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  ) : isError ? (
                    <Typography
                      fontWeight="bold"
                      variant="h3"
                      sx={{
                        textAlign: "center",
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                    >
                      {error?.response?.data?.message || error?.message}
                    </Typography>
                  ) : data?.data?.data?.length > 0 ? (
                    <>
                      <Table className={`align-items-center table-${mode}`} responsive>
                        <thead
                          style={{
                            backgroundColor: mode === "dark" ? "rgb(55, 65, 81)" : "#F9FAFB",
                            color: "#6B7280",
                            width: "100%",
                          }}
                        >
                          <tr>
                            {/* Order ID */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.order_number")}
                            </th>

                            {/* Status */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.status")}
                            </th>

                            {/* Amount */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.Amount")}
                            </th>

                            {/* User Balance */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.user_balance")}
                            </th>

                            {/* Order by */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.order_by")}
                            </th>

                            {/* Consume time */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("settlementRequest.request_date")}
                            </th>

                            {/* Show details */}
                            <th
                              style={{
                                textAlign: "center",
                                padding: "20px ",
                                fontSize: "12px",
                              }}
                              scope="col"
                            >
                              {t("report.show_details")}
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            backgroundColor: mode === "dark" ? "rgb(31, 42, 55)" : "white",
                            width: "100%",
                          }}
                        >
                          {all_permissions?.includes("Reject withdrawal") &&
                            data?.data?.data?.map((report, index) => (
                              <tr key={index}>
                                <td style={{ textAlign: "center" }}>{report?.order_id}</td>
                                <td
                                  style={{ textAlign: "center" }}
                                  className={"bg-" + statuses_colors[report?.status]}
                                >
                                  {t("report." + report?.status)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {report?.amount
                                    ? i18n.language === "en"
                                      ? `${report.amount} SAR`
                                      : `${report.amount} ريال`
                                    : i18n.language === "en"
                                    ? "0 SAR"
                                    : "0 ريال"}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {report?.user_balance
                                    ? i18n.language === "en"
                                      ? `${report.user_balance} SAR`
                                      : `${report.user_balance} ريال`
                                    : i18n.language === "en"
                                    ? "0 SAR"
                                    : "0 ريال"}
                                </td>
                                {/* Order by */}
                                <td style={{ textAlign: "center" }}>
                                  {report?.action_by?.email
                                    ? report?.action_by?.name
                                    : report?.action_by?.name || "-"}
                                </td>
                                {/* Transaction Date */}
                                <td style={{ textAlign: "center" }}>
                                  {formatDate(report?.completedTime || report?.created_at)}
                                </td>
                                {/* Show details */}
                                <ActionDropdown
                                  report={report}
                                  onShowDetails={handleShowDetails}
                                  onAcceptRequest={handleAcceptRequest}
                                  onRejectRequest={() => {
                                    setReportDetails(report);
                                    setOpenRejectModal(true);
                                  }}
                                />
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <EmptyContentMessage buttonText={false} />
                  )}

                  <Stack
                    direction="row"
                    sx={{
                      margin: "10px 0",
                      display: data?.data?.data?.length > 0 ? "flex" : "none",
                    }}
                    gap={2}
                    justifyContent={"center"}
                  >
                    <Pagination
                      count={data?.data?.last_page || 1}
                      shape="rounded"
                      value={page}
                      disabled={isLoading || isError}
                      onChange={(e, value) => setPage(value)}
                    />
                  </Stack>
                </Card>
              </div>
            </Row>
          </div>
        </Box>
      </Box>

      {/* Report information */}
      <GlobalModal
        modalHeaderTypography={t("report.report_details")}
        open={openModal}
        maxWidth="sm"
        handleClose={() => setOpenModal(false)}
      >
        <RequestDetails orderData={reportDetails} onClose={() => setOpenModal(false)} />
      </GlobalModal>
      <GlobalModal
        modalHeaderTypography={t("report.reject")}
        open={openRejectModal}
        maxWidth="sm"
        handleClose={() => setOpenRejectModal(false)}
      >
        <div>
          <TextField
            id="outlined-multiline-flexible"
            className="w-100"
            label={t("report.note")}
            multiline
            maxRows={3}
            onChange={(e) => setRejectNote(e.target.value)}
            value={rejectNote}
          />

          <Stack flexDirection={"row"} justifyContent={"center"} gap={3} mt={3}>
            <Button
              onClick={handleRejectRequest}
              variant="contained"
              color="error"
              disabled={rejectMutation.isPending}
            >
              {t("report.reject")}
            </Button>
            <Button onClick={() => setOpenRejectModal(false)}>{t("report.cancel")}</Button>
          </Stack>
        </div>
      </GlobalModal>
    </Box>
  );
}

export default SettlementRequests;
