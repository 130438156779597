import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import "../../css/index.css";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "components/BreadCrumbs";
import { useSelector } from "react-redux";
import useThemeMode from "hooks/useThemeMode";
import { Card, Row } from "reactstrap";
import EarningsDetails from "components/Modules/WithdrawalRequest/EarningsDetails";
import RequestsTable from "components/Modules/WithdrawalRequest/RequestsTable";

function SettlementRequest() {
  const { t } = useTranslation();
  const { mode } = useSelector((state) => state.theme);
  const themeMode = useThemeMode(mode);

  return (
    <Box minHeight="89.5vh" sx={{ padding: "36px" }}>
      <BreadCrumbs pageName={t("settlementRequest.label")} />
      <Box
        sx={{
          borderRadius: "16px",
          width: "100%",
          typography: "body1",
          backgroundColor: themeMode === "dark" ? "rgb(31, 42, 55)" : "white",
          padding: "24px",
        }}
      >
        <Typography
          fontWeight="bold"
          variant="h6"
          sx={{
            textAlign: "start",
            color: themeMode === "dark" ? "#fff" : "#000",
          }}
        >
          {t("settlementRequest.label")}
        </Typography>
        <Typography
          sx={{
            color: "gray",
            marginBottom: "16px",
            marginTop: "8px",
            textAlign: "start",
          }}
          variant="subtitle2"
        >
          {t("settlementRequest.description")}
        </Typography>

        <Box sx={{ minHeight: "calc( 100vh - 123px)" }}>
          {/* Page content */}

          <div className="p-0">
            <Row>
              <div className="col">
                <Card
                  style={{
                    backgroundColor: mode === "dark" ? "rgb(31, 42, 55)" : "white",
                    border: "none",
                  }}
                >
                  <Stack flexWrap={"wrap"} gap={3}>
                    {/* Details and send request */}
                    <EarningsDetails />

                    <RequestsTable />
                  </Stack>
                </Card>
              </div>
            </Row>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default SettlementRequest;
