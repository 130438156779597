import Api from "config/api";

export const GetCategories = async (page) => {
  try {
    const res = await Api.get("api/ecommerce/categories", {
      params: {
        per_page: 10,
        page: page,
      },
    });
    return res.data;
  } catch (error) {
    // console.error("Error fetching ser data:", error);
    return error;
  }
};

export const AddCategory = async (data) => {
  try {
    const res = await Api.post("api/ecommerce/categories/store", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  } catch (error) {
    // This makes sure Axios errors are passed to React Query's `onError`
    throw error;
  }
};

export const UpdateCategory = async (data) => {
  try {
    const res = await Api.post(`api/ecommerce/categories/update/${data.id}`, data.formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  } catch (error) {
    // This makes sure Axios errors are passed to React Query's `onError`
    throw error;
  }
};

export const DeleteCategory = async ({ id }) => {
  const res = await Api.delete(`api/ecommerce/categories/delete/${id}`);
  return res.data;
};
