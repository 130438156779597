import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  CircularProgress,
  IconButton,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import EmptyContentMessage from "components/EmptyContentMessage/EmptyContentMessage";
import GlobalModal from "components/GlobalModal/GlobalModal";
import Api from "config/api";
import useFormatDate from "hooks/useFormatDate";
import useThemeMode from "hooks/useThemeMode";
import useUserPermissions from "hooks/useUserPermissions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { notifyError } from "utilites/toastify";
import RequestDetails from "./RequestDetails";

const GetReportWithdrawals = async (query, page) => {
  const res = await Api.get(`/api/reports/withdrawals?${query}&page=${page}`);
  return res.data;
};

function RequestsTable() {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(`per_page=10`);
  const [reportDetails, setReportDetails] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const formatDate = useFormatDate();
  const { t, i18n } = useTranslation();
  const { mode } = useSelector((state) => state.theme);
  const themeMode = useThemeMode(mode);
  const all_permissions = useUserPermissions();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["withdrawal_requests", { query, page }],
    queryFn: () => GetReportWithdrawals(query, page),
    onError: (err) => {
      const errors = err.response.data.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          notifyError(errors[key]);
        }
      }
    },
  });

  const handleShowDetails = (data) => {
    setReportDetails(data);
    setOpenModal(true);
  };

  const statuses_colors = {
    pending: "warning",
    paid: "success",
    failed: "danger",
    refunded: "info",
    reversed: "info",
    cancelled: "danger",
    approved: "success",
    declined: "danger",
  };

  const allStatus = {
    pending: t("orders.pending"),
    completed: t("orders.completed"),
    cancelled: t("orders.cancelled"),
    delivered: t("orders.delivered"),
    returned: t("orders.returned"),

    active: t("orders.active"),
    inactive: t("orders.inactive"),
    deleted: t("orders.deleted"),

    paid: t("orders.paid"),
    failed: t("orders.failed"),
    refunded: t("orders.refunded"),

    processing: t("orders.processing"),

    // Additional statuses
    declined: t("orders.declined"),
    approved: t("orders.approved"),
    reversed: t("orders.reversed"),
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : isError ? (
        <Typography
          fontWeight="bold"
          variant="h3"
          sx={{
            textAlign: "center",
            color: themeMode === "dark" ? "#fff" : "#000",
          }}
        >
          {error?.response?.data?.message || error?.message}
        </Typography>
      ) : data?.data?.data?.length > 0 ? (
        <>
          <Table className={`align-items-center table-${mode}`} responsive>
            <thead
              style={{
                backgroundColor: mode === "dark" ? "rgb(55, 65, 81)" : "#F9FAFB",
                color: "#6B7280",
                width: "100%",
              }}
            >
              <tr>
                {/* Order ID */}
                <th
                  style={{
                    textAlign: "center",
                    padding: "20px ",
                    fontSize: "12px",
                  }}
                  scope="col"
                >
                  {t("report.order_number")}
                </th>

                {/* Status */}
                <th
                  style={{
                    textAlign: "center",
                    padding: "20px ",
                    fontSize: "12px",
                  }}
                  scope="col"
                >
                  {t("report.status")}
                </th>

                {/* Amount */}
                <th
                  style={{
                    textAlign: "center",
                    padding: "20px ",
                    fontSize: "12px",
                  }}
                  scope="col"
                >
                  {t("report.Amount")}
                </th>

                {/* Consume time */}
                <th
                  style={{
                    textAlign: "center",
                    padding: "20px ",
                    fontSize: "12px",
                  }}
                  scope="col"
                >
                  {t("settlementRequest.request_date")}
                </th>

                {/* Show details */}
                <th
                  style={{
                    textAlign: "center",
                    padding: "20px ",
                    fontSize: "12px",
                  }}
                  scope="col"
                >
                  {t("report.show_details")}
                </th>
              </tr>
            </thead>
            <tbody
              style={{
                backgroundColor: mode === "dark" ? "rgb(31, 42, 55)" : "white",
                width: "100%",
              }}
            >
              {data?.data?.data?.map((report, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>{report?.order_id}</td>
                  <td
                    style={{ textAlign: "center" }}
                    className={"bg-" + statuses_colors[report?.status]}
                  >
                    {allStatus[report?.status] || report?.status}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {report?.amount
                      ? i18n.language === "en"
                        ? `${report.amount} SAR`
                        : `${report.amount} ريال`
                      : i18n.language === "en"
                      ? "0 SAR"
                      : "0 ريال"}
                  </td>

                  {/* Transaction Date */}
                  <td style={{ textAlign: "center" }}>
                    {formatDate(report?.completedTime || report?.created_at)}
                  </td>

                  {/* Show details */}
                  <td style={{ textAlign: "center" }}>
                    <Tooltip title={t("report.show_details")}>
                      <IconButton
                        onClick={() => handleShowDetails(report)}
                        size="small"
                        color="primary"
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <EmptyContentMessage buttonText={false} />
      )}

      <Stack
        direction="row"
        sx={{
          margin: "10px 0",
          display: data?.data?.data?.length > 0 ? "flex" : "none",
        }}
        gap={2}
        justifyContent={"center"}
      >
        <Pagination
          count={data?.data?.last_page || 1}
          shape="rounded"
          value={page}
          disabled={isLoading || isError}
          onChange={(e, value) => setPage(value)}
        />
      </Stack>

      {/* Report information */}
      <GlobalModal
        modalHeaderTypography={t("report.report_details")}
        open={openModal}
        maxWidth="sm"
        handleClose={() => {
          setOpenModal(false);
        }}
      >
        <RequestDetails
          orderData={reportDetails}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      </GlobalModal>
    </>
  );
}

export default RequestsTable;
