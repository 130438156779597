import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import Api from "../../config/api";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { notifySuccess } from "utilites/toastify";
import { notifyError } from "utilites/toastify";
import { ArrowRightAltOutlined, RestartAlt } from "@mui/icons-material";
import i18n from "config/translationConfig";
import useUserPermissions from "hooks/useUserPermissions";
export const Transaction = async (data) => {
  const res = await Api.post("api/balances/deduction", data);
  return res.data;
};
export default function DeductionDevice() {
  const { t } = useTranslation();
  const { direction, mode } = useSelector((state) => state.theme);
  const { i18n } = useTranslation();
  const all_permissions = useUserPermissions();
  const [formValues, setFormValues] = useState({
    user_card: "",
    amount: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const { mutate, isPending } = useMutation({
    mutationFn: Transaction,
    onSuccess: (data) => {
      notifySuccess("Transaction Successful");
    },
    onError: (err) => {
      if (err?.response?.data?.error) {
        notifyError(err?.response?.data?.error);
      } else {
        notifyError("Transaction Falied");
      }
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    const { user_card, amount } = formValues;
    mutate({ user_card, amount });

    // Reset form values
    setFormValues({
      user_card: "",
      amount: ""
    });
  };
  return (
    <Box sx={{ padding: "40px 20px 10px 20px" }}>
      {all_permissions.includes("Deduct balance") && (
        <form onSubmit={handleSubmit}>
          <Stack gap={2}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box sx={{ width: "100%" }}>
                <label>{t("recharge.card")}</label>
                <TextField
                  fullWidth
                  name="user_card"
                  required
                  value={formValues.user_card}
                  onChange={handleInputChange}
                  inputProps={{ type: "number" }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "10px", // Add border radius
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <label>{t("recharge.am")}</label>
                <TextField
                  fullWidth
                  name="amount"
                  required
                  value={formValues.amount}
                  onChange={handleInputChange}
                  inputProps={{ type: "number" }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "10px", // Add border radius
                      },
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield", // Firefox
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none", // Chrome, Safari, Edge
                        margin: 0,
                      },
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">SAR</InputAdornment>,
                  }}
                />
              </Box>
            </Box>

            <Stack direction={"row"} justifyContent="flex-end">
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "black",
                  backgroundColor: "white",
                  fontSize: "15px",
                  transition: ".4s",
                  padding: "5px 20px",
                  textTransform: "none",
                  marginX: "1rem",
                  "&:hover": {
                    backgroundColor: "white",
                    opacity: ".8",
                  },
                }}
              >
                <RestartAlt sx={{ color: "#B3B3B3" }} />
                {i18n.language == "en" ? "Clear" : "مسح"}
              </Button>
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#255F6D",
                  fontSize: "15px",
                  transition: ".4s",
                  padding: "5px 20px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#255F6D",
                    opacity: ".8",
                  },
                }}
                disabled={isPending}
                variant="contained"
                type="submit"
              >
                {i18n.language == "en" ? "Deduction" : "خصم"}
                <ArrowRightAltOutlined />
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
    </Box>
  );
}
