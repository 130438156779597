import Api from "config/api";

export const GetReportWithdrawals = async (query, page) => {
  const res = await Api.get(`/api/reports/withdrawals?${query}&page=${page}`);
  return res.data;
};

export const ApproveWithdrawal = async (id) => {
  const res = await Api.post(`/api/balances/withdrawal/approve/${id}`);
  return res.data;
};

export const RejectWithdrawal = async (id, data) => {
  const res = await Api.post(`/api/balances/withdrawal/reject/${id}`, data);
  return res.data;
};
