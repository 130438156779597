import { useTranslation } from "react-i18next";

const useCurrencyFormatter = () => {
  const { i18n } = useTranslation();

  const formatCurrency = (amount = 0, currency = "SAR", arabicCurrency = "ريال") => {
    if (i18n.language === "en") {
      return `${amount} ${currency}`;
    } else {
      return `${amount} ${arabicCurrency}`;
    }
  };

  return { formatCurrency };
};

export default useCurrencyFormatter;
