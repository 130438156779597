import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";

export default function ActionDropdown({
  report,
  onShowDetails,
  onRejectRequest,
  onAcceptRequest,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation();

  // Open dropdown menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close dropdown menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <td style={{ textAlign: "center" }}>
      <Tooltip title="Show actions">
        <IconButton onClick={handleClick} size="small" color="primary">
          <MoreHoriz />
        </IconButton>
      </Tooltip>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* Show Details */}
        <MenuItem
          onClick={() => {
            handleClose();
            onShowDetails(report);
          }}
        >
          {t("report.show_details")}
        </MenuItem>
        {report.status === "pending" && (
          <>
            {/* Reject Request */}
            <MenuItem
              onClick={() => {
                handleClose();
                onRejectRequest(report);
              }}
              sx={{ color: "error.main" }}
            >
              {t("report.reject_request")}
            </MenuItem>
            {/* Accept Request */}
            <MenuItem
              onClick={() => {
                handleClose();
                onAcceptRequest(report);
              }}
              sx={{ color: "success.main" }}
            >
              {t("report.accept_request")}
            </MenuItem>
          </>
        )}
      </Menu>
    </td>
  );
}
