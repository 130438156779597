import { DarkModeRounded, LightModeRounded } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleDirection, toggleMode } from "../redux/slices/mode.slice";
import { logout, getUserData } from "../redux/slices/user.slice";
import PublicIcon from "@mui/icons-material/Public";

export default function BreadCrumbs({ pageName }) {
  const dispatch = useDispatch();
  const { direction, mode } = useSelector((state) => state.theme);
  const { data } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  // Trigger re-fetch when the component mounts and when data.profile_image changes
  useEffect(() => {
    dispatch(getUserData());
  }, [data.profile_image]);

  const handleClick = () => {
    if (direction === "ltr") {
      i18n.changeLanguage("ar");
      dispatch(toggleDirection("rtl"));
    } else {
      i18n.changeLanguage("en");
      dispatch(toggleDirection("ltr"));
    }
  };
  return (
    <Stack
      sx={{
        padding: "32px 10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: mode == "light" ? "rgba(0, 0, 0, 0.5)" : "rgba(250, 250, 250, 0.8)",
          fontSize: "20px",
        }}
      >
        {pageName}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <Button
          sx={{
            textTransform: "none",
            color: mode == "light" ? "rgba(0, 0, 0, 0.7)" : "rgba(250, 250, 250, 0.8)",
            gap: "8px",
          }}
          onClick={() => dispatch(toggleMode(mode === "dark" ? "light" : "dark"))}
        >
          {mode === "dark" ? (
            <LightModeRounded sx={{ color: "white" }} />
          ) : (
            <DarkModeRounded
              sx={{
                color: mode == "light" ? "rgba(0, 0, 0, 0.5)" : "rgba(250, 250, 250, 0.8)",
              }}
            />
          )}
          {mode === "dark" ? t("global.light") : t("global.dark")}
        </Button>
        <Button
          onClick={handleClick}
          sx={{
            color: `${mode == "light" ? "rgba(0, 0, 0, 0.7)" : "white"}`,
            gap: "8px",
            textTransform: "none",
          }}
        >
          <PublicIcon
            sx={{
              color: mode == "light" ? "rgba(0, 0, 0, 0.2)" : "rgba(250, 250, 250, 0.8)",
            }}
          />
          {direction === "ltr" ? "عربي" : "English"}
        </Button>
        <Button
          sx={{
            textTransform: "none",
            color: mode == "light" ? "rgba(0, 0, 0, 0.7)" : "rgba(250, 250, 250, 0.8)",
            gap: "8px",
          }}
          onClick={() => dispatch(logout())}
        >
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${
              data?.user?.profile_image
            }?t=${new Date().getTime()}`}
            alt={data?.user?.name}
            style={{
              width: "60px",
              height: "60px",
              padding: "10px",
              borderRadius: "100%",
            }}
          />
          {t("global.logout")}
        </Button>
      </Box>
    </Stack>
  );
}
